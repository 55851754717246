<template>
  <div class="txrecord">
    <title-top>提现记录</title-top>
    
    <div class="h20"></div>
    <div class="row align-center between whitescreen h146 mb20" v-for="(item,index) in list" :key="index">
        <div class="column ml25">
            <span class="font28 f-1A1A1A">{{res.channel_zh_cn+res.state_zh_cn}}</span>
            <span class="font24 f-666666">{{res.created_at | formatYear}}</span>
        </div>
        <span class="font36 f-F62341 mr25">￥{{res.amount_of_arrival}}</span>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import {formatDate} from 'common/util'
import { get_withdrawals } from "network/igou";
import TitleTop from '@/components/common/title/TitleTop'

export default {
  name: 'TxRecord',
  data(){
    return {
      list:[],
    }
  },
  mounted() {
    get_withdrawals().then(res=>{
      console.log(res.data)
      this.list = res.detail;
    })
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    },
    formatHs(time) {
      var date = new Date(time);
      return formatDate(date, 'hh:mm');
    },
    formatYear(time) {
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd');
    }
  },
  methods: {

  },
  components: {
    TitleTop
  }
}
</script>
<style  scoped>




</style>
